var DomUtils = module.exports;
[
  require("./lib/stringify"),
  require("./lib/traversal"),
  require("./lib/manipulation"),
  require("./lib/querying"),
  require("./lib/legacy"),
  require("./lib/helpers")
].forEach(function(ext){
  Object.keys(ext).forEach(function(key){
    DomUtils[key] = ext[key].bind(DomUtils);
  });
});
