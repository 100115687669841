import assign from './assign.js';
import assignIn from './assignIn.js';
import assignInWith from './assignInWith.js';
import assignWith from './assignWith.js';
import create from './create.js';
import defaults from './defaults.js';
import defaultsDeep from './defaultsDeep.js';
import entries from './entries.js';
import entriesIn from './entriesIn.js';
import extend from './extend.js';
import extendWith from './extendWith.js';
import findKey from './findKey.js';
import findLastKey from './findLastKey.js';
import forIn from './forIn.js';
import forInRight from './forInRight.js';
import forOwn from './forOwn.js';
import forOwnRight from './forOwnRight.js';
import functions from './functions.js';
import functionsIn from './functionsIn.js';
import get from './get.js';
import has from './has.js';
import hasIn from './hasIn.js';
import invert from './invert.js';
import invertBy from './invertBy.js';
import invoke from './invoke.js';
import keys from './keys.js';
import keysIn from './keysIn.js';
import mapKeys from './mapKeys.js';
import mapValues from './mapValues.js';
import merge from './merge.js';
import mergeWith from './mergeWith.js';
import omit from './omit.js';
import omitBy from './omitBy.js';
import pick from './pick.js';
import pickBy from './pickBy.js';
import result from './result.js';
import set from './set.js';
import setWith from './setWith.js';
import toPairs from './toPairs.js';
import toPairsIn from './toPairsIn.js';
import transform from './transform.js';
import unset from './unset.js';
import update from './update.js';
import updateWith from './updateWith.js';
import values from './values.js';
import valuesIn from './valuesIn.js';

export default {
  assign, assignIn, assignInWith, assignWith, create,
  defaults, defaultsDeep, entries, entriesIn, extend,
  extendWith, findKey, findLastKey, forIn, forInRight,
  forOwn, forOwnRight, functions, functionsIn, get,
  has, hasIn, invert, invertBy, invoke,
  keys, keysIn, mapKeys, mapValues, merge,
  mergeWith, omit, omitBy, pick, pickBy,
  result, set, setWith, toPairs, toPairsIn,
  transform, unset, update, updateWith, values,
  valuesIn
};
